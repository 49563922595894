import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SuggestedListing from '../../components/listing/SuggestedListing';
import ManageSuggestion from '../../components/listing/ManageSuggestion';
import moment from 'moment-timezone';

function formatISOInLocal(isoString) {
  return moment(isoString).format('MMM DD, h:mm A');
}

function SuggestionList({ suggestions, listing, editor,  fetchListing }) {
  const [showRejectedDetails, setShowRejectedDetails] = useState({});
  // Sort suggestions: approved first, then unapproved, then rejected
  const sortedSuggestions = suggestions.sort((a, b) => {
    if (a.status === 'approved' && b.status !== 'approved') return -1;
    if (a.status === 'unapproved' && b.status !== 'unapproved') return -1;
    if (a.status === 'rejected' && b.status !== 'rejected') return 1;
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  const toggleRejectedDetails = (id) => {
    setShowRejectedDetails((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return sortedSuggestions.map((suggestion) => {
    let bgColor = 'bg-gray-100';
    let textSize = 'text-base';
    if (suggestion.status === 'approved') bgColor = 'bg-green-200';
    if (suggestion.status === 'unapproved') bgColor = 'bg-orange-200';
    if (suggestion.status === 'rejected') {
      bgColor = 'bg-red-200';
      textSize = 'text-sm';
    }

    return (
      <div key={suggestion.id} className={`p-4 mb-4 rounded-lg shadow-md ${bgColor} ${textSize}`}>
        <div className="flex justify-between items-center">
          <span>
            Submitted At: {formatISOInLocal(suggestion.createdAt)} by{' '}
            {suggestion.user ? (
              <Link to={`/user/${suggestion.user.username}`} className="text-blue-600 hover:underline">
                @{suggestion.user.username}
              </Link>
            ) : 
              (suggestion.action === 'report' ? 'Report' : 'Integration')
            }
          </span>
          <button onClick={() => setShowRejectedDetails('')} className="text-blue-600 hover:underline">
            Close Suggestion
          </button>
        </div>

        {suggestion.comment && (
          <p className="mt-2">
            Comment: <span className="italic">{suggestion.comment}</span>
          </p>
        )}

        {suggestion.status === 'unapproved' && suggestion.suggestedListing && (
          <SuggestedListing suggestion={suggestion} currentListing={listing} region={listing.region} />
        )}

        {suggestion.status === 'approved' && (
                  <div className="mt-4">
                  <button
                    onClick={() => toggleRejectedDetails(suggestion.id)}
                    className="text-blue-600 hover:underline"
                  >
                    {showRejectedDetails[suggestion.id] ? 'Hide Details' : 'Show Details'}
                  </button>
                  {showRejectedDetails[suggestion.id] && suggestion.suggestedListing && (
                    <div className="mt-2">
                      <SuggestedListing suggestion={suggestion} currentListing={listing} region={listing.region} />
                    </div>
                  )}
                </div>)}
                

        {suggestion.status === 'rejected' && (
          <div className="mt-4">
            <button
              onClick={() => toggleRejectedDetails(suggestion.id)}
              className="text-blue-600 hover:underline"
            >
              {showRejectedDetails[suggestion.id] ? 'Hide Details' : 'Show Details'}
            </button>
            {showRejectedDetails[suggestion.id] && suggestion.suggestedListing && (
              <div className="mt-2">
                <SuggestedListing suggestion={suggestion} currentListing={listing} region={listing.region} />
              </div>
            )}
            <p>Status: <span className="font-bold text-red-600">Rejected</span></p>
          </div>
        )}

        {editor &&
          suggestion.status !== 'rejected' &&
          suggestion.status !== 'approved' && (
            <ManageSuggestion suggestion={suggestion} onChange={fetchListing} />
          )}
        
        {suggestion.status === 'approved' && (
          <p className="mt-4 font-bold text-green-600">Status: Approved</p>
        )}
      </div>
    );
  });
}

export default SuggestionList;
