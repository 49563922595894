import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';


import FAQ from './pages/static/FAQ';
import Home from './pages/static/Home';



import RegisterPage from './pages/user/RegisterPage';
import LoginPage from './pages/user/LoginPage';
import Logout from './pages/user/Logout';

//to import  profile 
import API from './pages/static/api';
import About from './pages/static/about';
import TermsOfService from './pages/static/terms';
import PrivacyPolicy from './pages/static/privacy';

import Admin from './pages/admin';
import Profile from './pages/user/Profile';
import UserDashboard from './pages/user/UserDashboard';
import UserProfile from './pages/user/UserProfile';

import Regions from './pages/region/Regions';
import RegionDetail from './pages/region/RegionDetail';
import EditRegionDetail from './pages/region/EditRegionDetail';

import Place from './pages/place/PlaceUUID';
import PlaceDetail from './pages/place/PlaceDetail';
import EditPlace from './pages/place/EditPlace';
import EditPlaceSuggestions from './pages/place/EditPlaceSuggestions';
import EditPlacePeople from './pages/place/EditPlacePeople';
import EditPlaceOrganizers from './pages/place/EditPlaceOrganizers';
import EditPlaceSettings from './pages/place/EditPlaceSettings';
import EditPlaceLogs from './pages/place/EditPlaceLogs';
import EditPlaceListings from './pages/place/EditPlaceListings';
import EditPlaceSeries from './pages/place/EditPlaceSeries';


import Organizer from './pages/organizer/OrganizerUUID';
import OrganizerReload from './pages/organizer/OrganizerReload';
import EditOrganizerDetail from './pages/organizer/EditOrganizerDetail';
import EditOrganizerListings from './pages/organizer/EditOrganizerListings';
import EditOrganizerPeople from './pages/organizer/EditOrganizerPeople';
import EditOrganizerPlaces from './pages/organizer/EditOrganizerPlaces';
import EditOrganizerSettings from './pages/organizer/EditOrganizerSettings';
import EditOrganizerSeries from './pages/organizer/EditOrganizerSeries';
import EditOrganizerLocations from './pages/organizer/EditOrganizerLocations';

//import Series from './pages/series/Series'; todo
import EditSeries from './pages/series/EditSeries'; 
import SeriresReload from './pages/series/SeriesReload';

import Listings from './pages/listing/Listings';
import Listing from './pages/listing/Listing';
import EditListing from './pages/listing/EditListing';


import EmailConfirmation from './pages/user/EmailConfirmation';
import PasswordResetRequest from './pages/user/PasswordResetRequest';
import PasswordReset from './pages/user/PasswordReset';
import Navbar from './components/utilities/navbar';
import EditPlaceIntegrations from './pages/place/EditPlaceIntegrations';

import OnBoarding from './pages/user/OnBoarding';
import Footer from './components/utilities/Footer';
import ProtectedRoute from './auth/ProtectedRoute';

import NotFound from './pages/static/NotFound.';


function App() {

  useEffect(() => {
    window.scrollTo(0, 0);

  },[])
  return (
      <div className="min-h-screen flex flex-col">
        <Navbar />
          <main className="flex-grow">
          <Routes>

          {/* static routes */}
          <Route path="/" element={<Home />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/api" element={<API />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path='/privacy' element={<PrivacyPolicy />} />



{/* region routes */}
          <Route path="/regions" element={<Regions />}/>
          <Route path="/region/:urlName" element={<RegionDetail />}/>
         { /* protected region routes role check */}
          <Route path="/region/:urlName/edit" element={
              <ProtectedRoute requiredRole="admin">
                <EditRegionDetail />
              </ProtectedRoute>
            }/>



           {/* Place Routes */}
          <Route path="/place/:uuid" element={<Place />} />
           <Route path="/region/:regionName/place/:placeName" element={<PlaceDetail />} />
          {/* Protected Edit Routes */}
          <Route path="/region/:regionName/place/:placeName/edit" element={
            <ProtectedRoute>
              <EditPlace />
            </ProtectedRoute>
          } />
          <Route path="/region/:regionName/place/:placeName/edit/settings" element={
            <ProtectedRoute>
              <EditPlaceSettings />
            </ProtectedRoute>
          } />
          <Route path="/region/:regionName/place/:placeName/edit/people" element={
            <ProtectedRoute>
              <EditPlacePeople />
            </ProtectedRoute>
          } />
          <Route path="/region/:regionName/place/:placeName/edit/organizers" element={
            <ProtectedRoute>
              <EditPlaceOrganizers />
            </ProtectedRoute>
          } />
          <Route path="/region/:regionName/place/:placeName/edit/suggestions" element={
            <ProtectedRoute>
              <EditPlaceSuggestions />
            </ProtectedRoute>
          } />
          <Route path="/region/:regionName/place/:placeName/edit/integrations" element={
            <ProtectedRoute>
              <EditPlaceIntegrations />
            </ProtectedRoute>
          } />
          <Route path="/region/:regionName/place/:placeName/edit/logs" element={
            <ProtectedRoute>
              <EditPlaceLogs />
            </ProtectedRoute>
          } />
          <Route path="/region/:regionName/place/:placeName/edit/listings" element={
            <ProtectedRoute>
              <EditPlaceListings />
            </ProtectedRoute>
          } />
          <Route path="/region/:regionName/place/:placeName/edit/series" element={
            <ProtectedRoute>
              <EditPlaceSeries />
            </ProtectedRoute>
          } />

        

        {  /* organizer routes */}
        <Route path="/organizer/:uuid" element={<Organizer />} />

        <Route path="/region/:regionName/organizer/:organizerName" element={<OrganizerReload />} />

        {/* Protected Edit Routes */}
        <Route path="/region/:regionName/organizer/:organizerName/edit" element={
          <ProtectedRoute>
            <EditOrganizerDetail />
          </ProtectedRoute>
        } />
        <Route path="/region/:regionName/organizer/:organizerName/edit/listings" element={
          <ProtectedRoute>
            <EditOrganizerListings />
          </ProtectedRoute>
        } />
        <Route path="/region/:regionName/organizer/:organizerName/edit/people" element={
          <ProtectedRoute>
            <EditOrganizerPeople />
          </ProtectedRoute>
        } />
        <Route path="/region/:regionName/organizer/:organizerName/edit/places" element={
          <ProtectedRoute>
            <EditOrganizerPlaces />
          </ProtectedRoute>
        } />
        <Route path="/region/:regionName/organizer/:organizerName/edit/settings" element={
          <ProtectedRoute>
            <EditOrganizerSettings />
          </ProtectedRoute>
        } />
        <Route path="/region/:regionName/organizer/:organizerName/edit/series" element={
          <ProtectedRoute>
            <EditOrganizerSeries />
          </ProtectedRoute>
        } />
        <Route path="/region/:regionName/organizer/:organizerName/edit/locations" element={
          <ProtectedRoute>
            <EditOrganizerLocations />
          </ProtectedRoute>
        } />

        {/* event routes */}
          <Route path="/listings" element={<Listings  />}/>
          <Route path="/listing/:listingId" element={<Listing  />}/>
          <Route path="/event/:listingId" element={<Listing  />}/>




          <Route path="/listing/:listingId/edit" element={
          <ProtectedRoute>
            <EditListing />
          </ProtectedRoute>
        } />
          

          <Route path="/series/:seriesUUID" element={<SeriresReload  />}/>


          <Route path="/series/:seriesUUID/edit" element={
          <ProtectedRoute>
            <EditSeries />
          </ProtectedRoute>
        } />

          <Route path="/admin" element={
          <ProtectedRoute requiredRole="superadmin">
            <Admin />
          </ProtectedRoute>
        } />

        <Route path="/user" element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        } />

    <Route path="/dashboard" element={
          <ProtectedRoute>
            <UserDashboard />
          </ProtectedRoute>
        } />
        
        <Route path="/user/:username" element={
            <UserProfile />
        } />

<Route path="/onboarding" element={
          <ProtectedRoute>
            <OnBoarding />
          </ProtectedRoute>
        } />

<Route path="/signup/*" element={<RegisterPage />} />


          <Route path="/login/*" element={<LoginPage />} />
          <Route path="/logout" element={<Logout />} />

          <Route path="/confirm/:token" element={<EmailConfirmation />} />
          <Route path="/forgotpassword" element={<PasswordResetRequest/>} />
          <Route path="/resetpassword/:token" element={<PasswordReset/>} />

        <Route path="*" element={<NotFound />} />

        </Routes>
        </main>
      <Footer />
    </div>      
  );
}

export default App;
