import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

function SuggestedListing({ currentListing, suggestion,region}) {
// do something with additional data or in the cloud i think
const [currentOrganizers,setCurrentOrganizers] = useState(false);
const [suggestionOrganizers,setSuggestionOrganizers] = useState(false);

  const formatDateTime = (dateTime,timezone) => {
    const options = {
      timeZone: timezone && timezone,
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
  };
    return new Date(dateTime).toLocaleString('en-US', options);
  };
  // check for differences between currentListing and suggestion.suggestedListing

useEffect(()=> {
const suggestionListingids = suggestion.suggestedListing.organizers.map(o=>(o.organizerId));
const suggestionListingOrgs = suggestion.suggestedListing.organizers.map(o=>(o.organizer));
const currentListingids = currentListing.organizers.map(o=>(o.organizerId));
const currentListingOrgs = currentListing.organizers.map(o=>(o.organizer));
setSuggestionOrganizers({ids: suggestionListingids, organizers: suggestionListingOrgs});
setCurrentOrganizers({ids: currentListingids, organizers: currentListingOrgs});
},[currentListing,suggestion])

  return (



    <div key={suggestion.uuid} className="p-4mb-4">
      <p className="text-2xl font-bold ">
        <span className='text-bold'>Comment:</span>{suggestion.comment}
      </p>
  {currentListing.name !== suggestion.suggestedListing.name && 
     <p className="text-2xl font-bold ">
        <span className="text-red-500 line-through">{currentListing.name || 'No Main Listing Title'}</span> - <span className="text-green-500">{suggestion.suggestedListing.name}</span>
     </p>
  }
       <p className="mt-1 mb-2"> 
       {currentListing.eventStatus !== suggestion.suggestedListing.eventStatus && 
     <p className="text-lg font-bold ">
        <span className="text-red-500 line-through">{currentListing.eventStatus }</span> - <span className="text-green-500">{suggestion.suggestedListing.eventStatus}</span>
     </p>
  }
 

      {currentOrganizers && suggestionOrganizers && (currentOrganizers.ids.length > 0 || suggestionOrganizers.ids.length > 0 ) && (JSON.stringify(currentOrganizers.ids) !== JSON.stringify(suggestionOrganizers.ids)) && 
      
      <div className='text-xl'><span>Organizers: </span>
      {currentOrganizers.ids.filter(organizerId=>!suggestionOrganizers.ids.includes(organizerId)).map(
        (organizerId,index) => { const organizer = currentOrganizers.organizers.find(organizer=> organizer.id === organizerId); return  <p key={index} className="text-red-500 line-through"><Link to={`/region/${currentListing.region.urlName}/organizer/${organizer.urlName}`}>{organizer.name}</Link></p>}
      )}
      
      {suggestionOrganizers.ids.filter(organizerId=>!currentOrganizers.ids.includes(organizerId)).map(
        (organizerId,index) => { const organizer = suggestionOrganizers.organizers.find(organizer=> organizer.id === organizerId); return <p key={index} className="text-green-500 underline"><Link to={`/region/${currentListing.region.urlName}/organizer/${organizer.urlName}`}>{organizer.name}</Link></p>}
      )
      }

        </div>}

            </p>
            <p className="text-xl">
      {currentListing.startDate && (currentListing.startDate!==suggestion.suggestedListing.startDate) && ( <p> Start Date:
       <span className="text-red-500 line-through">{formatDateTime(currentListing.startDate, currentListing.timezone)}</span>
       <span className="text-green-500 ">{formatDateTime(suggestion.suggestedListing.startDate, suggestion.suggestedListing.timezone)}</span>
       </p>
      )}
      {currentListing.endDate && (currentListing.endDate!==suggestion.suggestedListing.endDate) && ( <p> Start Date:
       <span className="text-red-500 line-through">{formatDateTime(currentListing.endDate,currentListing.timezone)}</span>
       <span className="text-green-500">{formatDateTime(suggestion.suggestedListing.endDate,suggestion.suggestedListing.timezone)}</span>
       </p>
      )}
</p>
      {currentListing.url && (currentListing.url!==suggestion.suggestedListing.url) &&  (
        <p>
             <span className="text-red-500 line-through">{currentListing.url}</span>
       <a
            href={suggestion.suggestedListing.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="text-green-500 underline">          
            {suggestion.suggestedListing.url}
            </span>
          </a>
        </p>
      )}

{(suggestion.suggestedListing.type || currentListing.type) && currentListing.type !== suggestion.suggestedListing.type && 
     <p className="text-2xl">
       Type: <span className="text-red-500 line-through">{currentListing.type || 'No Type'}</span> - <span className="text-green-500">{suggestion.suggestedListing.type || 'No Type'}</span>
     </p>
  }
{(suggestion.suggestedListing.note || currentListing.note)&& currentListing.note !== suggestion.suggestedListing.note && 
     <p className="  ">
       Note: <span className="text-red-500 line-through">{currentListing.note || 'No Note'}</span> - <span className="text-green-500">{suggestion.suggestedListing.note || 'No Note'}</span>
     </p>
  }

{(suggestion.suggestedListing.description || currentListing.description) && currentListing.description !== suggestion.suggestedListing.description && 
     <p className="  ">
       Description: <span className="text-red-500 line-through">{currentListing.description || 'No description'}</span> - <span className="text-green-500">{suggestion.suggestedListing.description || 'No description'}</span>
     </p>
  }
  {(suggestion.suggestedListing.alternateName || currentListing.alternateName) && currentListing.alternateName !== suggestion.suggestedListing.alternateName && 
     <p className="  ">
       Alternate Name: <span className="text-red-500 line-through">{currentListing.alternateName || 'No Alternate Name'}</span> - <span className="text-green-500">{suggestion.suggestedListing.alternateName || 'No Alternate Name'}</span>
     </p>
  }
    {(suggestion.suggestedListing.room || currentListing.room) && currentListing.room !== suggestion.suggestedListing.room && 
     <p className=" ">
       Building Room: <span className="text-red-500 line-through">{currentListing.room || 'No Room'}</span> - <span className="text-green-500">{suggestion.suggestedListing.room || 'No Room'}</span>
     </p>
  }
    {(suggestion.suggestedListing.inLanguage || currentListing.inLanguage) && currentListing.inLanguage !== suggestion.suggestedListing.inLanguage && 
     <p className="  ">
       Language: <span className="text-red-500 line-through">{currentListing.inLanguage || 'No Language'}</span> - <span className="text-green-500">{suggestion.suggestedListing.inLanguage || 'No Language'}</span>
     </p>
  }
{(suggestion.suggestedListing.isAccessibleForFree || currentListing.isAccessibleForFree) && currentListing.isAccessibleForFree !== suggestion.suggestedListing.isAccessibleForFree && (
  <p className="  ">
    Is Accessible for Free: 
    <span className="text-red-500 line-through">
      {currentListing.isAccessibleForFree ? 'Yes' : 'No'}
    </span> - 
    <span className="text-green-500">
      {suggestion.suggestedListing.isAccessibleForFree ? 'Yes' : 'No'}
    </span>
  </p>
)}

{(suggestion.suggestedListing.image || currentListing.image) && currentListing.image !== suggestion.suggestedListing.image && 
  <p className="  ">
    Image: <span className="text-red-500 line-through">{currentListing.image || 'No Image'}</span> - <span className="text-green-500">{suggestion.suggestedListing.image || 'No Image'}</span>
  </p>
}
{(suggestion.suggestedListing.alt || currentListing.alt) && currentListing.alt !== suggestion.suggestedListing.alt && 
  <p className="  ">
    Alt Text: <span className="text-red-500 line-through">{currentListing.alt || 'No Alt Text'}</span> - <span className="text-green-500">{suggestion.suggestedListing.alt || 'No Alt Text'}</span>
  </p>
}
{(suggestion.suggestedListing.sponsor || currentListing.sponsor) && currentListing.sponsor !== suggestion.suggestedListing.sponsor && 
     <p className="  ">
       Sponsor: <span className="text-red-500 line-through">{currentListing.sponsor || 'No Sponsor'}</span> - <span className="text-green-500">{suggestion.suggestedListing.sponsor || 'No Sponsor'}</span>
     </p>
  }
{(suggestion.suggestedListing.keywords || currentListing.keywords) && currentListing.keywords !== suggestion.suggestedListing.keywords && 
  <p className="  ">
    Keywords: <span className="text-red-500 line-through">{currentListing.keywords || 'No Keywords'}</span> - <span className="text-green-500">{suggestion.suggestedListing.keywords || 'No Keywords'}</span>
  </p>
}
{(suggestion.suggestedListing.doorTime || currentListing.doorTime) && currentListing.doorTime && (currentListing.doorTime !== suggestion.suggestedListing.doorTime) && 
  <p>
    Door Time: 
    <span className="text-red-500 line-through">{formatDateTime(currentListing.doorTime,currentListing.timezone)}</span> - 
    <span className="text-green-500 ">{formatDateTime(suggestion.suggestedListing.doorTime,suggestion.suggestedListing.timezone)}</span>
  </p>
}

{currentListing.status !== suggestion.suggestedListing.status && 
  <p className="  ">
    Status: <span className="text-red-500 line-through">{currentListing.status || 'No Status'}</span> - <span className="text-green-500">{suggestion.suggestedListing.status || 'No Status'}</span>
  </p>
}
{(suggestion.suggestedListing.eventAttendanceMode || currentListing.eventAttendanceMode) && currentListing.eventAttendanceMode !== suggestion.suggestedListing.eventAttendanceMode && 
  <p className="">
    Attendance Mode: <span className="text-red-500 line-through">{currentListing.eventAttendanceMode || 'No Attendance Mode'}</span> - <span className="text-green-500">{suggestion.suggestedListing.eventAttendanceMode || 'No Attendance Mode'}</span>
  </p>
}

{(suggestion.suggestedListing.offerUrl || currentListing.offerUrl) && currentListing.offerUrl !== suggestion.suggestedListing.offerUrl && 
  <p className="">
    Offer URL: <span className="text-red-500 line-through">{currentListing.offerUrl || 'No Offer URL'}</span> - <span className="text-green-500">{suggestion.suggestedListing.offerUrl || 'No Offer URL'}</span>
  </p>
}

{(suggestion.suggestedListing.price || currentListing.price) && currentListing.price !== suggestion.suggestedListing.price && 
  <p className="">
    Price: <span className="text-red-500 line-through">{currentListing.price || 'No Price'}</span> - <span className="text-green-500">{suggestion.suggestedListing.price || 'No Price'}</span>
  </p>
}

{(suggestion.suggestedListing.priceCurrency || currentListing.priceCurrency) && currentListing.priceCurrency !== suggestion.suggestedListing.priceCurrency && 
  <p className="">
    Price Currency: <span className="text-red-500 line-through">{currentListing.priceCurrency || 'No Price Currency'}</span> - <span className="text-green-500">{suggestion.suggestedListing.priceCurrency || 'No Price Currency'}</span>
  </p>
}

{(suggestion.suggestedListing.audience || currentListing.audience) && currentListing.audience !== suggestion.suggestedListing.audience && 
  <p className="">
    Audience: <span className="text-red-500 line-through">{currentListing.audience || 'No Audience'}</span> - <span className="text-green-500">{suggestion.suggestedListing.audience || 'No Audience'}</span>
  </p>
}





{currentListing.worksPresented && suggestion.suggestedListing.worksPresented && (
  <div>
    {currentListing.worksPresented.map((work) => {
      console.log(suggestion.suggestedListing.worksPresented[0])
      const suggestedWork = suggestion.suggestedListing.worksPresented.find(suggested => suggested.oldUuid === work.uuid);
      const differences = [];
      const fields = ['name', 'duration', 'director', 'year', 'videoFormat', 'description', 'inLanguage', 'countryOfOrigin', 'productionCompany', 'TMDB', 'description', 'abstract', 'genre', 'subtitleLanguage'];

      if (suggestedWork) {
        fields.forEach(field => {
          if (work[field] !== suggestedWork[field]) {
            differences.push(
              <div key={field} className="mb-2  ">
                <div className="line-through text-red-500">
                  {work[field] && <p>{field.charAt(0).toUpperCase() + field.slice(1)}: {work[field]}</p>}
                </div>
                <div className="text-green-500">
                  {suggestedWork[field] && <p>{field.charAt(0).toUpperCase() + field.slice(1)}: {suggestedWork[field]}</p>}
                </div>
              </div>
            );
          }
        });
        return (
          <div key={work.uuid} className="mb-4  border-b-2 border-black ">
            {differences}
          </div>
        );
      } else {
        {fields.forEach(field => {
          differences.push(
            <div key={field} className="mb-2">
              <div className="line-through text-red-500">
                {work[field] && <p>{field.charAt(0).toUpperCase() + field.slice(1)}: {work[field]}</p>}
              </div>
            </div>
) })};
        return <div className="mb-2  border-b-2 border-black">
        <div className="line-through text-red-500">
        {differences}
         </div>
        
      </div>;
      }
    })}
  </div>
)}

    
    </div>
  );
}

export default SuggestedListing;
