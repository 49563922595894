import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddRegion from '../components/admin/AddRegion';
import RegionInfo from '../components/region/RegionInfo';
import AdminUsers from '../components/admin/AdminUsers';
import AdminPlaceIntegrations from '../components/admin/AdminPlaceIntegrations';
import InviteAdmin from '../components/admin/InviteAdmin';

function Admin() {
  const navigate = useNavigate();
  const [regions, setRegions] = useState([]);
  const [users, setUsers] = useState([]);
  const [showRegions, setShowRegions] = useState(false);
  const [placeIntegrations, setPlaceIntegrations] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
      fetchAdminData();
      
  }, []);

  useEffect(() => {
    document.title = `Admin - Zeitgeists`;
  }, []);

  const fetchAdminData = async () => {
    try {
      const response = await fetch('/api/admin/', {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setRegions(data.regions || []);
        setUsers(data.users || []);
        setPlaceIntegrations(data.placeIntegrations || []);
      } else if (response.status === 403) {
        navigate('/dashboard');
      } else if (response.status === 401) {
        if (response.status === 401) {
          navigate('/login'); // Redirect to login
        }
      } else {
        setErrorMessage('Failed to load admin data');
      }
    } catch (error) {
      console.error('Error fetching admin data:', error);
      setErrorMessage('An error occurred while fetching data.');
    }
  };

  const handleDeleteRegion = async (regionId) => {
    if (!window.confirm('Confirm Delete Region')) return;
    if (!window.confirm('Again Confirm Delete Region')) return;

    try {
      const response = await fetch(`/api/region/${regionId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setErrorMessage(`${regionId} deleted!`);
        fetchAdminData();
      } else if (response.status === 404) {
        setErrorMessage('Region not found!');
      } else if (response.status === 401 || response.status === 403) {
        setErrorMessage('Permission denied!');
          navigate('/login'); // Redirect to login
      } else {
        setErrorMessage('Failed to delete region');
      }
    } catch (error) {
      console.error('Error deleting region:', error);
      setErrorMessage('An error occurred while deleting the region.');
    }
  };

  const updateIntegrationDirectory = async (integration, directory) => {
    if (!integration || !directory) return;

    try {
      const response = await fetch(`/api/integration/${integration.id}`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ directory }),
      });

      if (response.ok) {
        console.log('Integration directory updated successfully');
        fetchAdminData(); // Refresh data
      } else {
        console.error('Failed to update integration directory');
      }
    } catch (error) {
      console.error('Error updating integration directory:', error);
    }
  };


  const handleRegenerateFeed = async () => {
    if (!window.confirm('Are you sure you want to regenerate the feed? This action cannot be undone.')) {
      return;
    }

    console.log('Regenerating feed...');
      try{
      const response = await fetch('/api/feed/regenerate', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        console.log(response);
        console.log('Feed regenerated successfully');
      } else {
        console.error('Failed to regenerate feed');
      }
    }
    catch (error) {
      console.error('Error regenerating feed:', error);
    }
  }
  return (
    <div className='p-4 mt-36'>
      <h2 className='text-xl font-bold '>Admin:</h2>
      <button onClick={() => setShowRegions(!showRegions)} className='underline text-blue-600'>
        {showRegions ? 'Close Regions' : 'Show Regions'}
      </button>

      {showRegions && (
        <div>
          <h3 className='text-lg font-semibold text-center '>Regions:</h3>
          <div className='grid grid-cols-3'>
            {regions.map((r) => (
              <div key={r.id}>

                <RegionInfo region={r} />
                <div className='text-center'>
                {r.hide && <p className='text-purple-600 text-center font-bold text-lg mb-4'>Hidden</p>}

                  <button className="underline font-bold text-red-600" onClick={() => handleDeleteRegion(r.id)}>
                    Delete Region
                  </button>
                </div>
              </div>
            ))}
          </div>
          <br />
          {errorMessage && <p className="text-red-600">{errorMessage}</p>}
          <AddRegion onChange={fetchAdminData} />
        </div>
      )}

      {placeIntegrations.length > 0 && (
        <AdminPlaceIntegrations
          placeIntegrations={placeIntegrations}
          onChange={updateIntegrationDirectory}
        />
      )}

      {users.length > 0 && <div>
        <AdminUsers regions={regions} users={users} />
        <div className='mt-10'>
          <InviteAdmin />
          </div>
        </div>}
      <div className='mt-10'>
        <button onClick={handleRegenerateFeed} className='underline text-blue-600'>
          Regenerate Feed
        </button>
        </div>
    </div>
  );
}
   
export default Admin;
