// frontend/src/Place.js
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import LoadingScreen from '../../components/utilities/LoadingScreen';

function Organizer() {
    const { uuid } = useParams();
    const navigate = useNavigate();
    const isLoading = true;
    useEffect(() => {
        // Fetch the route from the backend
        fetch(`/api/organizer/${uuid}`)
            .then((response) => {
                if (response.redirected) {
                    navigate(response.url.replace(window.location.origin, ''), { replace: true });
                } else {
                    throw new Error('Organizer not found');
                }
            })
            .catch((error) => {
                console.error(error);
                navigate('/404', { replace: true }); // Navigate to a 404 page if needed
            });
    }, [uuid, navigate]);

    return <LoadingScreen isLoading={true} message="Fetching Organizer ..." />
}

export default Organizer;
