import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

function SetPlaceOrgsNew({ control, setValue, getValues, thePlace, preOrgs, suggest, region, tempPlace }) {
    const [places, setPlaces] = useState(thePlace ? [thePlace] : []);
    const [selectedOrganizers, setSelectedOrganizers] = useState((preOrgs?.map(org => org.organizerId)) || []);

    useEffect(() => {
        if (!tempPlace) {
            listingPlaces();
        }
        
        if (!places.some(place => place.id === getValues('placeId'))) {
            setValue('placeId', '');
            const orgIds = preOrgs?.map(org => org.organizerId);
            setSelectedOrganizers(orgIds || []);
            setValue('organizers', []);
        }
    }, [suggest, region]);

    const listingPlaces = async () => {
        try {
            if (tempPlace) return;
            
            const fetchUrl = thePlace 
                ? `/api/region/${region}/listing/place/${thePlace}` 
                : suggest 
                    ? `/api/region/${getValues('regionId')}/listings/places/all` 
                    : `/api/region/${getValues('regionId')}/listings/places`;

            const response = await fetch(fetchUrl, { credentials: 'include' });
            const resData = await response.json();
            setPlaces([resData]);
        } catch (error) {
            console.error(error);
        }
    }

    const handlePlaceChange = (place) => {
        setValue('placeId', place.id);
        setValue('geo', place.geo);
        setSelectedOrganizers([]);
        setValue('organizers', []);
    };

    const handleOrganizerChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedOrganizers(selectedOptions);
        setValue('organizers', selectedOptions);
        
        const currentPlace = places.find(place => place.id === (thePlace || getValues('placeId')));
        setValue('organizersData', currentPlace?.organizers?.filter(o => selectedOptions.includes(o.id.toString())));
    };

    const currentPlace = places.find(place => place.id === (thePlace || getValues('placeId')));

    return (
        <div className="text-black">
            {!tempPlace && !thePlace && places && (
                <Controller
                    control={control}
                    name="placeId"
                    rules={{ required: "Selecting a place is required" }}
                    render={({ field, fieldState: { error } }) => (
                        <div className="mb-6">
                            <label htmlFor="placeSelect" className="block mb-2 text-sm font-medium text-gray-900">
                                Select a Place:
                            </label>
                            <select 
                                id="placeSelect"
                                {...field}
                                onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    const place = places.find(p => p.value === selectedValue);
                                    if (place) {
                                        handlePlaceChange(place);
                                    }
                                    field.onChange(e);
                                }}
                                className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                            >
                                <option value="">Select a place</option>
                                {places.map(place => (
                                    <option key={place.id} value={place.id}>
                                        {place.name}
                                    </option>
                                ))}
                            </select>
                            {error && <p>{error.message}</p>}
                        </div>
                    )}
                />
            )}

            {currentPlace?.organizers?.length > 0 ? (
                <>
                    <Controller
                        control={control}
                        name="organizers"
                        render={({ field }) => (
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900">
                                    Assign Organizers:
                                </label>
                                <select
                                    id="organizerSelect"
                                    {...field}
                                    multiple
                                    onChange={handleOrganizerChange}
                                    value={selectedOrganizers}
                                    className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 rounded shadow leading-tight focus:outline-none focus:shadow-outline overflow-auto"
                                >
                                    {currentPlace.organizers.map(organizer => (
                                        <option key={organizer.id} value={organizer.id}>
                                            {organizer.name}
                                        </option>
                                    ))}
                                </select>
                                <p>Choose organizers from the list (scroll) and click the red dot to remove a selection.</p>
                            </div>
                        )}
                    />
                    <div className="flex flex-wrap gap-2">
                        {selectedOrganizers.map(organizerId => {
                            const organizer = currentPlace.organizers.find(o => o.id.toString() === organizerId);
                            return organizer && (
                                <div key={organizerId} className="flex items-center gap-2 bg-blue-200 rounded px-4 py-2">
                                    {organizer.name}
                                    <button
                                        type="button"
                                        onClick={() => {
                                            const newSelectedOrganizers = selectedOrganizers.filter(id => id !== organizerId);
                                            setSelectedOrganizers(newSelectedOrganizers);
                                            setValue('organizers', newSelectedOrganizers);
                                        }}
                                        className="bg-red-500 rounded-full w-4 h-4 flex items-center justify-center text-white"
                                    />
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : (
                <div className="mb-3">{!tempPlace && 'No Organizers'}</div>
            )}

            {tempPlace && (
                <div className="mb-2 bg-gray-100 p-4 rounded-lg shadow-sm">
                    <h3 className="text-md font-semibold mb-2 text-gray-800">
                        Temporary Place for {preOrgs?.[0]?.organizer.name}
                    </h3>
                    <p className="text-md text-gray-700"><strong>Name:</strong> {tempPlace.name}</p>
                    <p className="text-md text-gray-700"><strong>Address:</strong> {tempPlace.address}</p>
                    <p className="text-md text-gray-700"><strong>Geo:</strong> Latitude {tempPlace.latitude}, Longitude {tempPlace.longitude}</p>
                    <p className="text-md text-gray-700">
                        <strong>URL:</strong> 
                        <a href={tempPlace.url} className="text-blue-500" target="_blank" rel="noopener noreferrer">
                            {tempPlace.url}
                        </a>
                    </p>
                    <p className="text-md text-gray-700"><strong>Neighborhood:</strong> {tempPlace.area}</p>
                    <p className="text-md text-gray-700"><strong>Description:</strong> {tempPlace.description}</p>
                </div>
            )}
        </div>
    );
}

export default SetPlaceOrgsNew;