import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';

function ManageSuggestion({ suggestion, onChange,newListing }) {

  const [status,setStatus] = useState(suggestion.status || 'unapproved');
  const [updateMessage, setUpdateMessage] = useState(''); // State for update message

 const changeStatus = async (e) => {
  if(!window.confirm('Confirm')) {
    setStatus('unapproved')
    return
  }
  setUpdateMessage('Loading')

  try {
    console.log(e)
    const response = await fetch(`/api/suggestion/${suggestion.uuid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Include cookies
      body: JSON.stringify({'status': e}),
    });
    if (response.ok) {
      // Success: Listing updated successfully
      // You can display a success message here if needed
      const data = await response.json()
      if(data.listing) {
        setUpdateMessage(`Listing created.`);
      } else {
        setUpdateMessage("Updated. Wait a second to refresh.");
      }

      setTimeout(() => {
        onChange();
      }, 1000); 
      // You can close the form here by calling a callback function if needed
  } else {
      // Error: An error occurred while updating the listing
      const errorData = await response.json();
      console.error(errorData.message); // Log the error message for debugging
      setUpdateMessage("Error updating.");

  }

  } catch (error) {
    // Handle fetch error
    setUpdateMessage("Error updating.");
    console.error(error);
  }
 }
  


 const handleStatus = (e) => {
  const newStatus = e.target.value;
  setStatus(newStatus);

  setTimeout(() => {
    changeStatus(newStatus); 
  }, 1000); 
 }
  return (
    <div className="text-left">
      <form onSubmit={changeStatus}>
      <label>Status:</label>
          <label className="pl-4 pr-4 text-yellow-500">
            <input
              type="radio"
              name="status"
              value="unapproved"
              checked={status === 'unapproved'}
              onChange={handleStatus}
            />
            Pending Review
          </label>
          <label className="pr-4 text-red-500">
            <input
              type="radio"
              name="status"
              value="rejected"
              checked={status === 'rejected'}
              onChange={handleStatus}
            />
            Rejected
          </label>
          <label className="pr-4 text-green-500">
            <input
              type="radio"
              name="status"
              value="approved"
              checked={status === 'approved'}
              onChange={handleStatus}
            />
            Approved
          </label>
        <span>   {updateMessage}</span>
      </form>

<p></p>
    </div>
 );
}

export default ManageSuggestion;
